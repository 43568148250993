// DAILY BEET PALETTE
$color-db-purple: #940152;
$color-db-green: #6cbd7d;
$color-db-teal: #cfebdf;
$color-db-orange: #f2784f;
$color-db-gold: #edcb6e;
$color-db-cream: #fdf9ec;

// BLUE GIANT PALETTE
$color-bluegiant-blue: #0b1b8f;
$color-bluegiant-light-blue: #6a8ed1;
$color-bluegiant-red: #ff4c3d;

// BLUE OAK PALETTE
$color-bloak-blue: #1a1d48;
$color-bloak-piggy-pink: #f74b8a;
$color-bloak-pink: $color-bloak-piggy-pink;
$color-bloak-mustard: #ffc438;
$color-bloak-eggnog: #fadda8;
$color-bloak-pigs-fly-blue: #73d2e9;
$color-bloak-light-blue: $color-bloak-pigs-fly-blue;
$color-bloak-aquarium-gravel: #00c79d;
$color-bloak-finger-lickin-brown: #6f1c00;
$color-bloak-brown: $color-bloak-finger-lickin-brown;


// BUBS PALETTE
$color-bubs-purple: #CA6FDA;
$color-bubs-red: #EA4B2C;
$color-bubs-wax-paper: #FEE7CB;
$color-bubs-brown: $color-bubs-wax-paper;
$color-bubs-charcoal: #29241A;
$color-bubs-lettuce-green: #B1F193;
$color-bubs-green: $color-bubs-lettuce-green;

//  TREPS PALETTE

$color-treps-blue: #0097ce;
$color-treps-navy: #2c365c;
$color-treps-steel: #707070;
$color-treps-orange: #ff945a;
$color-treps-special-sauce: $color-treps-orange;

// CONTENT
$color-body-bg: #fff;
$color-text: #444;
$color-heading-text: $color-db-purple;
$color-navbar: #fff;

// LINKS
$color-link-text: $color-text;
$color-link-text-active: $color-db-purple;

// INPUTS
$color-input-border: $color-db-green;

// BUTTONS
$color-button-bg: $color-db-purple;
$color-button-disabled-bg: darken(desaturate($color-button-bg, 20%), 10%);
$color-button-text: white;

// EDITOR
$color-add: $color-db-green; // #37c120;
$color-delete: #ff1a1a;
