@use "sass:math";

@mixin btn-size($xl-size, $padding: $xl-size * 1.75) {
  $lg-pcnt: 0.9;
  $md-pcnt: 0.85;

  @include padding-lr($padding);

  margin-top: 0.3em;
  margin-bottom: 0.3em;
  font-size: $xl-size !important;

  @media (max-width: 1200px) {
    @include padding-lr($padding * $lg-pcnt);

    font-size: ($xl-size * $lg-pcnt) !important;
  }

  @media (max-width: 960px) {
    @include padding-lr($padding * $md-pcnt);

    font-size: ($xl-size * $md-pcnt) !important;
  }
}

@mixin font-size($xl-size, $xs-x: 0.5, $sm-x: 0.6, $md-x: 0.7, $lg-x: 0.85) {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
  font-size: $xl-size !important;

  @media (max-width: 1200px) {
    font-size: ($xl-size * $lg-x) !important;
  }

  @media (max-width: 960px) {
    font-size: ($xl-size * $md-x) !important;
  }

  @media (max-width: 600px) {
    font-size: ($xl-size * $sm-x) !important;
  }

  @media (max-width: 414px) {
    font-size: ($xl-size * $xs-x) !important;
  }
}

@mixin padding-lr($p) {
  padding-right: $p;
  padding-left: $p;
}

@mixin prose-mirror-font {
  @content;
  > .ProseMirror .pm-el {
    > p,
    > span {
      @content;
    }
  }
}

.asap--font {
  $fam: 'Asap', sans-serif;

  @include prose-mirror-font {
    font-family: $fam;
  }
}

.blanch--font {
  $fam: 'Blanch Caps Regular', sans-serif;

  @include prose-mirror-font {
    font-family: $fam;
  }
}

.archivo--font {
  $fam: 'Archivo Narrow', sans-serif;

  @include prose-mirror-font {
    font-family: $fam;
    letter-spacing: math.div(1em, 34);
  }
}

.hello-bloomie--font {
  $fam: 'HBSerifReg-Regular', serif;

  @include prose-mirror-font {
    font-family: $fam;
    font-weight: normal;
  }
}

.helvetica--font {
  $fam: 'proxima nova', 'Helvetica Neue', sans-serif;

  @include prose-mirror-font {
    font-family: $fam;
    font-weight: normal;
  }
}

.rubik--font {
  $fam: 'Rubik', sans-serif;

  @include prose-mirror-font {
    font-family: $fam;
    font-weight: 500;
    letter-spacing: 0.074em;
  }
}
.ranga--font {
  $fam: 'Ranga', cursive;

  @include prose-mirror-font {
    font-family: $fam;
  }
}

.aesthet--font {
  $fam: 'aesthet-nova', serif;

  font-family: $fam;

  @include prose-mirror-font {
    font-family: $fam;
    letter-spacing: 0;
  }
}

.motor--font {
  $fam: 'motor', serif;

  @include prose-mirror-font {
    font-family: $fam;
  }
}

.freight-pro--font {
  $fam: 'freight-neo-pro', sans-serif;

  @include prose-mirror-font {
    font-family: $fam;
  }
}

.montserrat--font {
  $fam: 'Montserrat', Arial, sans-serif;

  @include prose-mirror-font {
    font-family: $fam;
  }
}

.averia--font {
  $fam: 'Averia Sans Libre', cursive;

  font-style: italic;

  @include prose-mirror-font {
    font-family: $fam;
    font-style: italic;
  }
}

.poppins--font {
  $fam: 'Poppins', sans-serif;
  font-family: $fam;

  @include prose-mirror-font {
    font-family: $fam;
  }
}

.futura--font {
  $fam: 'Futura PT', sans-serif;
  font-family: $fam;

  @include prose-mirror-font {
    font-family: $fam;
  }
}

.futura-bold--font {
  $fam: 'Futura PT Bold', sans-serif;
  font-family: $fam;

  @include prose-mirror-font {
    font-family: $fam;
  }
}

.futura-cond--font {
  $fam: 'Futura Cond PT', sans-serif;
  font-family: $fam;

  @include prose-mirror-font {
    font-family: $fam;
  }
}

.header-1 {
  @include font-size(34px);
}
.header-2 {
  @include font-size(45px);
}
.header-3 {
  @include font-size(56px);
}
.header-4 {
  @include font-size(112px);
}

.r-headline {
  @include font-size(24px, 1, 1, 1, 1);
}

.r-title {
  @include font-size(20px, 1, 1, 1, 1);

  line-height: 1.3 !important;
}
