




































































@import '@design';
@import '@styleMixins';

.other-mention.hover {
  .other-mentions-link::after {
    width: 100%;
  }
}

.press-title {
  a {
    color: $color-bloak-blue;
    text-decoration: none;
  }
}

.other-mentions-link {
  position: relative;
  padding-bottom: 0.2em;
  text-decoration: none;
  text-transform: uppercase;

  @include font-size(25px, 0.7, 0.75, 0.85);

  // stylelint-disable-next-line
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 2px;
    content: '';
    background: $color-bloak-pigs-fly-blue;
    transition: width 0.4s ease-in-out;
  }

  &.small::after {
    left: 50%;
    transform: translateX(-50%);
  }
}
